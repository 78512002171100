<template>
  <div class="adUniExam">
    <h2>成人高考</h2>
    <div class="adUniExamContent">
      <p>
        &emsp;&emsp;成人高等学校招生全国统一考试(Adult university entrance
        exam)简称成人高考，是我国成人高等学校选拔合格的毕业生以进入更高层次学历教育的入学考试，属于国民教育系列教育，已经列入国家招生计划。
      </p>
      <p>
        &emsp;&emsp;成人高考同普通高考一样，都是选拔人才的国家考试，只是由于本身的学习特征需要，才在学习形式等环节上有所差别，进行了一定区分。考生需参加教育部组织的全国统一考试，由各省市自治区、直辖市统一组织录取。设立之初，是为解决在岗人员的学历教育和继续教育问题，参加者多为成年人，是国家高等教育重要组成部分。
      </p>
      <p>
        &emsp;&emsp;成人高考分高起专、高起本、专升本，选拔要求因层次、地域、学科、专业的不同而有所区别。经过系统的学习后，本科毕业生通过毕业论文答辩、学位英语考核达到规定成绩，符合学位申请条件的，可申请授予学士学位，并可继续攻读硕士学位和博士学位。
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.adUniExam {
  padding: 70px 0px;
  .adUniExamContent {
    line-height: 30px;
    font-size: 16px;
    p {
      margin: 15px;
    }
  }
}
</style>
